import { Ref, computed, ref } from 'vue'

export function useSelectedList<T extends { id: string }> () {
  const items: Ref<T[]> = ref([])

  const itemsId = computed(() => items.value.map((item) => item.id))

  function toggleOneItem (item: T) {
    const isOnList = items.value.find((listItem) => listItem.id === item.id)

    if (isOnList) {
      items.value = []
      return
    }

    items.value = [item]
  }

  function addItems (newItems: T[]) {
    items.value = [...new Set([...items.value, ...newItems])]
  }

  function removeItem (removedItem: T) {
    items.value = items.value.filter((item) => item.id !== removedItem.id)
  }

  function toggleItem (item: T) {
    const isOnList = items.value.find((listItem) => listItem.id === item.id)
    if (isOnList) {
      removeItem(item)
      return
    }

    addItems([item])
  }

  function addRange (item: T, tableItems: T[]) {
    const lastSelectedItemId = items.value[0]?.id

    if (!lastSelectedItemId) {
      addItems([item])
      return
    }

    const selectedIndex = tableItems.findIndex((tableItem) => tableItem.id === item.id)
    const lastSelectedIndex = tableItems.findIndex((tableItem) => tableItem.id === lastSelectedItemId)
    let result: T[] = []
    if (selectedIndex > lastSelectedIndex) {
      result = [...tableItems].slice(lastSelectedIndex, selectedIndex + 1)
    } else {
      result = [...tableItems].slice(selectedIndex, lastSelectedIndex)
    }

    addItems(result)
  }

  return {
    items,
    itemsId,
    toggleOneItem,
    addRange,
    toggleItem
  }
}