export function colorsConvert (color: string): string {
  switch (color) {
    case 'green':
      return '#a5ff8e'
    case 'red':
      return '#ff9696'
    case 'gray':
      return '#E4E5E8'
    case 'orange':
      return '#FFE7B8'
    case 'blue':
      return '#A8FAFF'
    case 'violet':
      return '#FFCCFA'
    case 'white':
      return '#ffffff'
    default:
      return color
  }
}

export function translateColorsName (name: string): string {
  switch (name) {
    case 'green':
      return 'Зелёный'
    case 'red':
      return 'Красный'
    case 'gray':
      return 'Серый'
    case 'orange':
      return 'Оранжевый'
    case 'blue':
      return 'Голубой'
    case 'violet':
      return 'Фиолетовый'
    case 'white':
      return 'Белый'
    default:
      return name
  }
}