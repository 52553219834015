
import { PropType, computed, defineComponent, onMounted, ref, watch } from 'vue'
import Datepicker from 'vuejs-datetimepicker'

export default defineComponent({
  // eslint-disable-next-line vue/no-unused-components
  components: { Datepicker },
  props: {
    setValue: {
      type: Function as PropType<(newVal: string) => void>,
      required: true
    }
  },
  setup (props) {
    function setFormatValue (newVal: string) {
      props.setValue(newVal.replace(' ', 'T'))
    }

    const datepicker = ref<any>(null)

    onMounted(() => {
      datepicker.value.days = ['пн', 'вт', 'сп', 'чт', 'пт', 'сб', 'вс']
      datepicker.value.months = [
        'январь',
        'февраль',
        'март',
        'апрель',
        'май',
        'июнь',
        'июль',
        'август',
        'сентябрь',
        'октябрь',
        'ноябрь',
        'декабрь'
      ]

      const dateInputs = (datepicker.value.$root.$el as HTMLElement).querySelectorAll('#tj-datetime-input')
      for (const dateInput of dateInputs) {
        (dateInput as HTMLInputElement).readOnly = true
      }
    })

    function openContextMenu (event: Event) {
      event.preventDefault()
      datepicker.value.toggleCal()
    }

    const isShowClose = computed(() => {
      return datepicker.value?.date
    })

    return { setFormatValue, datepicker, openContextMenu, isShowClose }
  }
})
