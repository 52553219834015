
import { computed, defineComponent, PropType, reactive, ref } from 'vue'
import { DefaultSelect, ModalForm } from 'apptimizm-ui'
import axios from '@/axios'
import { getCeleryTask } from '@/core/celery'
import { mutations, store } from '@/store'
import { host } from '@/const'
import { ButtonLoader } from '@/UI/loader'

export interface ISelectItem {
  id: string
  name: string
}

export default defineComponent({
  props: {
    close: {
      type: Function as PropType<() => void>,
      required: true
    },
    zoneIds: {
      type: Object as PropType<string[]>,
      required: true
    }
  },
  setup (props) {
    const filters = [
      { id: 'barcode', name: 'По штрих-коду' },
      { id: 'vendor_code', name: 'По коду товара' }
    ]

    const documentTypes = [
      { id: 'pdf', name: 'PDF' },
      { id: 'excel', name: 'Excel' }
    ]

    const selectedParams = reactive({
      filter: filters[0],
      documentType: { id: '', name: '' }
    })

    const projectId = computed(() => store.currentProject.id)

    const isLoading = ref(false)

    async function submit () {
      if (isLoading.value) return
      isLoading.value = true

      try {
        const responseData = (await axios.post(`${host}/api/v1/zone/inventory_in_zones_in_zones/`, {
          project: projectId.value,
          document_type: selectedParams.documentType.id,
          group_by: selectedParams.filter.id,
          zones: props.zoneIds
        })).data
        const celaryResult = await getCeleryTask(responseData.task_id)
        window.open(celaryResult.data.result)
        mutations.pushNotification('Отчет по инвентаризации в зонах сформирован')
      } catch (error) {
        mutations.pushNotification('Ошибка формирования отчета', true)
      } finally {
        isLoading.value = false
      }
    }

    return () => (<div class="report-zones">
      <ModalForm
        cancel={props.close}
        confirm={() => {}}
        header='Отчет по инвентаризации в зонах'
        slots={{
          default: () => (
            <>
              <DefaultSelect
                class="select-placeholder-offset"
                modelValue={selectedParams.filter}
                constantPlaceholder={false}
                onValueChange={(v: ISelectItem) => { selectedParams.filter = v } }
                items={filters}
              />
              <DefaultSelect
                class="select-placeholder-offset"
                modelValue={selectedParams.documentType}
                constantPlaceholder={false}
                placeholder="Выберите тип документа"
                onValueChange={(v: ISelectItem) => { selectedParams.documentType = v } }
                items={documentTypes}
              />
            </>
          ),
          buttons: () => <button
            disabled={!selectedParams.documentType.id}
            onClick={submit}
            class="primary-button"
          ><ButtonLoader hidden={!isLoading.value}/>Сформировать</button>
        }}
      />
    </div>)
  }
})
