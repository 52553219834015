import { IExpose } from '@/UI/CastomTable/index.vue'
import { MessagesWebSocket } from '@/core/web-socket'
import { Area } from '@/pages/areas/types'
import { DocumentEntity } from '@/pages/documentation/types'
import { mutations } from '@/store'
import { Ref } from 'vue'

export function changeTableRow<T extends Ref<IExpose<Area|DocumentEntity>>> (table: T, message: MessagesWebSocket) {
  if (message.type.includes('update_')) updateTable(table, message, false)
  if (message.type.includes('delete_')) updateTable(table, message, true)
  if (message.type.includes('new_')) addNewRow(table, message)
}

function updateTable<T extends Ref<IExpose<Area|DocumentEntity>>> (table: T, message: MessagesWebSocket, isDelete: boolean) {
  const isThisTable = table.value.items.findIndex(item => message.type.includes(item.typeTable))
  const isRow = table.value.items.findIndex(item => message.data.id === item.id)

  if (isRow !== -1 && isThisTable !== -1) {
    isDelete ? table.value.items.splice(isRow, 1) : table.value.items.splice(isRow, 1, message.data)
    mutations.deleteMessageWS(message)
  }
}

function addNewRow<T extends Ref<IExpose<Area|DocumentEntity>>> (table: T, message: MessagesWebSocket) {
  const isRow = table.value?.items.findIndex(item => message.data.id === item.id)

  if (isRow === -1 && message.data.id) {
    table.value.items.push(message.data)
    mutations.deleteMessageWS(message)
  }
}