import { defineComponent, PropType } from '@vue/runtime-core'
import { ModalForm, DefaultSelect, usePaginatedApi, LineLoader, NumberInput } from 'apptimizm-ui'
import Checkbox from '@/UI/checkbox'
import { computed, ComputedRef, ref, Ref, watch } from 'vue'
import { Employee, EmployeeMeta } from '../employee/types'
import { ISelectValue } from '../projects/types'
import axios from '@/axios'
import { store } from '../../store'
import { FormFields, FormFieldsMeta } from './types'
import { IEventWithErrors } from '@/core/types'
import { howManyAreas } from './index.vue'
import { ButtonLoader } from '@/UI/loader'

export default defineComponent({
  props: {
    close: {
      type: Function as PropType<() => void>,
      required: true
    },
    roles: {
      type: Array as PropType<ISelectValue[]>,
      required: true
    },
    selectedAreas: {
      type: Array as PropType<string[]>,
      required: true
    },
    reloadTable: {
      type: Function as PropType<() => void>,
      default: () => {}
    },
    numberMessaggeReload: {
      type: Number,
      default: 0
    }
  },
  setup (props) {
    const root = ref(null) as unknown as Ref<HTMLElement>
    const target = ref(null) as unknown as Ref<HTMLElement>
    const form = ref(new FormFields())
    const formMeta = new FormFieldsMeta()
    const employeeMeta = new EmployeeMeta()
    const searchParams = ref<{[ key: string ]: string}>({
      project: store.currentProject.id,
      role: form.value.role.value
    })

    const isSubmit = ref(false)

    const { items, reload, loadNext, isLoading } = usePaginatedApi(
      employeeMeta.endpoint,
      axios,
      searchParams,
      ref(500),
      'page',
      (v) => employeeMeta.load(v),
      true,
      'results',
      'count'
    )

    const observer = new IntersectionObserver(async (entries) => {
      if (entries[0].isIntersecting) {
        await loadNext()
      }
    }, { threshold: 0, root: root.value })

    const changeRole = (role: ISelectValue) => {
      let change = ''

      if (['counter_scan', 'controller'].includes(role.value)) change = 'counter'
      if (['auditor', 'auditor_controller'].includes(role.value)) change = 'auditor'
      if (role.value === 'auditor_external') change = 'auditor_external'
      if (role.value === 'storage') change = 'storage'

      return change
    }

    const setRole = async (role: ISelectValue) => {
      form.value.role = role
      searchParams.value.role = changeRole(role)
      await reload()
    }
    const addAllEmployees = () => {
      if (form.value.employees.length === items.value.length) form.value.employees = []
      else form.value.employees = items.value.map(e => e.id)
    }

    watch(() => target.value, (to) => {
      if (to) observer.observe(target.value)
      else observer.disconnect()
    })

    const addEmployee = (id: string) => {
      if (form.value.employees.includes(id)) form.value.employees = form.value.employees.filter(e => e !== id)
      else form.value.employees.push(id)
    }

    const isSelectedAreas = computed(() => !!props.selectedAreas.length)

    const submit = async () => {
      isSubmit.value = true
      let data = null

      if (isSelectedAreas.value) {
        data = {
          project: store.currentProject.id,
          zones: props.selectedAreas,
          employee_ids: form.value.employees,
          role: form.value.role.value
        }
        howManyAreas.value = props.selectedAreas.length
      } else {
        data = {
          project: store.currentProject.id,
          zone_start_id: form.value.startSerialNumber,
          zone_end_id: form.value.endSerialNumber,
          employee_ids: form.value.employees,
          role: form.value.role.value
        }
        howManyAreas.value = Math.abs(form.value.endSerialNumber - form.value.startSerialNumber)
      }

      const responseData = (await axios.post(formMeta.endpointIssusing, data)).data
      if (responseData.status_code === 400 && responseData.errors) {
        const eventError400: IEventWithErrors = new Event('Error 400')
        eventError400.errors = responseData.errors
        window.dispatchEvent(eventError400)
      }

      observer.disconnect()
      props.close()
      isSubmit.value = false
    }

    const disabled: ComputedRef<boolean> = computed(() => {
      if (isSelectedAreas.value) {
        if (!form.value.employees.length) return true
      } else {
        if (!form.value.employees.length) return true
        if (!form.value.startSerialNumber || !form.value.endSerialNumber) return true
        if (form.value.startSerialNumber > form.value.endSerialNumber) return true
      }
      return false
    })

    const modalForm = () => (
      <ModalForm
        cancel={props.close}
        confirm={() => {}}
        header='Выдача задания'
        slots={{
          default: () => (
            <div class="mt-4">
              {isSelectedAreas.value
                ? null
                : <div class="row cg-10 mt-4 mb-2">
                  <label>
                    <NumberInput
                      class={`short input-placeholder-up ${!form.value.startSerialNumber ? 'hide-zero' : ''}`}
                      placeholder="Зоны с..."
                      modelValue={form.value.startSerialNumber}
                      onValueChange={(v: number) => { form.value.startSerialNumber = parseInt(String(v)) } }
                    />
                  </label>
                  <label>
                    <NumberInput
                      class={`short input-placeholder-up ${!form.value.endSerialNumber ? 'hide-zero' : ''}`}
                      placeholder="по..."
                      modelValue={form.value.endSerialNumber}
                      onValueChange={(v: number) => { form.value.endSerialNumber = parseInt(String(v)) } }
                    />
                  </label>
                </div>
              }
              <DefaultSelect
                class="select-placeholder-offset"
                modelValue={form.value.role}
                idKey="value"
                titleKey="name"
                placeholder="Выберите роль"
                onValueChange={(v: { id: string, name: string }) => setRole({ name: v.name, value: v.id }) }
                items={props.roles.map(v => ({ name: v.name, id: v.value }))}
              />
              <div class="mt-2">
                <Checkbox
                  modelValue={items.value.length > 0 && form.value.employees.length === items.value.length}
                  onClick={addAllEmployees} placeholder="Все пользователи"
                />
              </div>
              <div class='line-loader-container mt-2'>
                {isLoading.value && <LineLoader/>}
              </div>
              {items.value.length !== 0 && <ul class="choice-list" ref={root}>
                {items.value.map((item: Employee) => <li
                  class={form.value.employees.includes(item.id) ? 'choice-item active' : 'choice-item'}
                  onClick={() => { addEmployee(item.id) } }
                >{item.username}</li>)}
                <div ref={target}></div>
              </ul>}
            </div>
          ),
          buttons: () =>
            <button
              disabled={disabled.value || isSubmit.value}
              onClick={submit}
              class="primary-button mt-3">
              <ButtonLoader hidden={!isSubmit.value}/>
              Назначить
            </button>
        }}
      />
    )

    return () => (
      <div>{modalForm()}</div>
    )
  }
})