
import { filters4booleanField, filters4numberField, filters4textField } from '@/UI/CastomTable/Filters.vue'
import CastomTable, { IExpose, IHeader, IParams } from '@/UI/CastomTable/index.vue'
import Checkbox from '@/UI/checkbox'
import axios from '@/axios'
import { useContextMenu } from '@/composables/use-context-menu'
import { useSelectedList } from '@/composables/use-selected-list'
import { mutations, store } from '@/store'
import { Ref } from '@vue/runtime-core'
import { ModalForm } from 'apptimizm-ui'
import { PropType, computed, defineComponent, nextTick, ref, watch } from 'vue'
import ContextMenu from './ContextMenu.vue'
import { IAreaFilter4table, TTypeModal4Areas, howManyAreas } from './index.vue'
import { Area, AreaMeta } from './types'
import { changeTableRow } from '@/composables/use-ws-message-type'
import table from '../products-in-areas/table'

export default defineComponent({
  props: {
    table: {
      type: Object as PropType<Ref<IExpose<Area>>>,
      required: true
    },
    tableFilter: {
      type: Object as PropType<IAreaFilter4table>,
      required: true
    },
    isAutoAssignEnable: {
      type: Object as PropType<Ref<boolean>>,
      required: true
    },
    setTypeModal: {
      type: Function as PropType<(type: TTypeModal4Areas) => void>,
      required: true
    },
    selectedList: {
      type: Object as PropType<ReturnType<typeof useSelectedList>>,
      required: true
    },
    updateAllParams: {
      type: Function as PropType<(params: IParams) => void>,
      default: () => {}
    }
  },
  setup (props) {
    const areaMeta = new AreaMeta()

    const usersConverter = (count: number) => {
      if (!count) return ''
      const cCount = count.toString()
      const lastTwo = parseInt(cCount.slice(-2) || '')
      if (cCount.slice(-1) === '0' || (lastTwo > 10 && lastTwo < 20) || parseInt(cCount.slice(-1)) >= 5) return `${cCount} пользователей на зоне`
      else if (cCount.slice(-1) === '1') return `${cCount} пользователь на зоне`
      else return `${cCount} пользователя на зоне`
    }

    const markIsAutoAssignment = async (item: Area, isAutoAssignment?: 'add' | 'remove') => {
      if (item.isAutoAssignment && !item.storageName) {
        mutations.pushNotification('Назначьте склад для исключения из автоназначения.', true)
        return
      }
      if (isAutoAssignment) {
        item.isAutoAssignment = isAutoAssignment === 'add'
      } else {
        item.isAutoAssignment = !item.isAutoAssignment
      }
      await axios.patch(areaMeta.endpoint + '/' + item.id, areaMeta.dump(item))
      mutations.pushNotification(item.isAutoAssignment ? `Зона ${item.serialNumber} добавлена в автоназначение.` : `Зона ${item.serialNumber} исключена из автоназначения.`)
    }

    // Управление модалкой с подтверждением автоназначения
    const isShowConfirmModal = ref(false)

    function closeConfirmModal () {
      isShowConfirmModal.value = false
    }

    const isAddAutoAssignment = ref(false)

    const text4ConfirmModal = computed(() => {
      return `Вы уверены, что хотите ${isAddAutoAssignment.value ? 'добавить в автоназначение ' : 'исключить из автоназначения'} выделенные зоны?`
    })

    function openConfirmModal (newVal4isAddAutoAssignment: boolean) {
      isShowConfirmModal.value = true
      isAddAutoAssignment.value = newVal4isAddAutoAssignment
    }

    function markIsAutoAssignment4contextMenu () {
      props.selectedList.items.value.forEach((area) => {
        markIsAutoAssignment(area as Area, isAddAutoAssignment.value ? 'add' : 'remove')
      })
      closeConfirmModal()
      props.table.value.reload()
    }
    //

    function selectArea (e: MouseEvent, item: Area) {
      e.preventDefault()

      if (e.shiftKey) {
        props.selectedList.addRange(item, props.table.value.items)
        return
      }

      if (e.altKey) {
        props.selectedList.toggleItem(item)
        return
      }

      props.selectedList.toggleOneItem(item)
    }

    const headers: IHeader[] = [
      { name: 'Номер', sortAndFilterKey: 'serial_number', filterTypes: filters4numberField },
      { name: 'Код Зоны', sortAndFilterKey: 'code', filterTypes: filters4textField },
      { name: 'Название зоны', sortAndFilterKey: 'title', filterTypes: filters4textField },
      { name: 'Название склада', sortAndFilterKey: 'storage_name', filterTypes: filters4textField },
      { name: 'Кол-во ШК', sortAndFilterKey: 'barcode_amount', filterTypes: filters4numberField },
      { name: 'Статус', sortAndFilterKey: 'status', filterTypes: filters4booleanField },
      { name: 'Пустая', sortAndFilterKey: 'is_empty', filterTypes: filters4booleanField },
      { name: 'Счетчик', sortAndFilterKey: 'counter_scan', filterTypes: filters4textField },
      { name: 'Подсчет (Счетчик)', sortAndFilterKey: 'is_counter_scan_count', filterTypes: filters4booleanField },
      { name: 'УК', sortAndFilterKey: 'controller', filterTypes: filters4textField },
      { name: 'Подсчет (УК)', sortAndFilterKey: 'is_controller_count', filterTypes: filters4booleanField },
      { name: 'Внешний аудитор', sortAndFilterKey: 'auditor_external_controller', filterTypes: filters4textField },
      { name: 'Подсчет (Внеш. Ауд.)', sortAndFilterKey: 'is_auditor_external_controller_count', filterTypes: filters4booleanField },
      { name: 'Аудитор', sortAndFilterKey: 'auditor', filterTypes: filters4textField },
      { name: 'Подсчет (Ауд)', sortAndFilterKey: 'is_auditor_scan_count', filterTypes: filters4booleanField },
      { name: 'Аудитор УК', sortAndFilterKey: 'auditor_controller', filterTypes: filters4textField },
      { name: 'Подсчет (Ауд.УК)', sortAndFilterKey: 'is_auditor_controller_count', filterTypes: filters4booleanField },
      { name: 'Сотрудник склада', sortAndFilterKey: 'storage', filterTypes: filters4numberField },
      { name: 'Подсчет (Сотр.склада)', sortAndFilterKey: 'is_storage_count', filterTypes: filters4booleanField }
    ]

    const getUser = (employee: any): string => {
      if (!employee?.username) return '(Пользователь удалён)'
      else if (employee.is_deleted) return `${employee.username} (Удалён)`
      else return employee.username
    }

    const contextMenu = useContextMenu()

    function openContextMenu (event: MouseEvent, selectedArea: Area) {
      if (!props.selectedList.itemsId.value.includes(selectedArea.id)) {
        props.selectedList.toggleOneItem(selectedArea)
      }
      const block = document.getElementById('myTable')

      contextMenu.toggle(event, block?.offsetHeight)
    }

    const line = (item: Area, maxWidthOfColumns?: number[]) => {
      return (
        <tr
          onClick={(e: MouseEvent) => selectArea(e, item)}
          onContextmenu={(e: MouseEvent) => openContextMenu(e, item)}
          class={['area-item', 'main', {
            selected: props.selectedList.itemsId.value.includes(item.id),
            'not-auto-assignment': !item.isAutoAssignment
          }]}
        >
          <td style={{ maxWidth: `${maxWidthOfColumns?.[0]}px` }}>{item.serialNumber}</td>
          <td style={{ maxWidth: `${maxWidthOfColumns?.[1]}px` }}>{item.code !== '' ? item.code : 'Нет данных'}</td>
          <td style={{ maxWidth: `${maxWidthOfColumns?.[2]}px` }} class="hover">
            <div class="td-warapper">
              { props.isAutoAssignEnable.value && (<Checkbox modelValue={item.isAutoAssignment} onClick={() => markIsAutoAssignment(item)} placeholder=""/>)}
              <span class="ml-2">{item.name}</span>
              { props.isAutoAssignEnable.value && (<div class="table-hover">{item.isAutoAssignment ? 'Исключить зону из автоназначения' : 'Добавить зону в автоназначение'}</div>)}
            </div>
          </td>
          <td style={{ maxWidth: `${maxWidthOfColumns?.[3]}px` }}>{item.storageName}</td>
          <td style={{ maxWidth: `${maxWidthOfColumns?.[4]}px` }}>{item.barcodeAmount}</td>
          <td style={{ maxWidth: `${maxWidthOfColumns?.[5]}px` }}>
            <div class="td-warapper">
              <span class={item.status.value === 'not_ready' ? 'status mr-2' : 'status mr-2 done'}/>
              <span>{item.status.name}</span>
            </div>
          </td>
          <td style={{ maxWidth: `${maxWidthOfColumns?.[6]}px` }} class="center">
            <Checkbox modelValue={item.isEmpty} onClick={() => {}}/>
          </td>
          <td style={{ maxWidth: `${maxWidthOfColumns?.[7]}px` }} class="link">
            {areaMeta.getUserCount(item.counterScan)}
            {item.counterScan.length ? <div class="table-hover">{item.counterScan.map(i => <span>{getUser(i.employee)}</span>)}</div> : null}
          </td>
          <td style={{ maxWidth: `${maxWidthOfColumns?.[8]}px` }} class="center">
            <Checkbox modelValue={item.isCounterScanCount} onClick={() => {}}/>
          </td>

          <td style={{ maxWidth: `${maxWidthOfColumns?.[9]}px` }} class="link">
            {areaMeta.getUserCount(item.counterController)}
            {item.counterController.length ? <div class="table-hover">{item.counterController.map(i => <span>{getUser(i.employee)}</span>)}</div> : null}
          </td>
          <td style={{ maxWidth: `${maxWidthOfColumns?.[10]}px` }} class="center">
            <Checkbox modelValue={item.isCounterControllerCount} onClick={() => {}}/>
          </td>

          <td style={{ maxWidth: `${maxWidthOfColumns?.[11]}px` }} class="link">
            {areaMeta.getUserCount(item.auditorExternalController)}
            {item.auditorExternalController.length ? <div class="table-hover">{item.auditorExternalController.map(i => <span>{getUser(i.employee)}</span>)}</div> : null}
          </td>
          <td style={{ maxWidth: `${maxWidthOfColumns?.[12]}px` }} class="center">
            <Checkbox modelValue={item.isAuditorExternalControllerCount} onClick={() => {}}/>
          </td>

          <td style={{ maxWidth: `${maxWidthOfColumns?.[13]}px` }} class="link">
            {areaMeta.getUserCount(item.auditorScan)}
            {item.auditorScan.length ? <div class="table-hover">{item.auditorScan.map(i => <span>{getUser(i.employee)}</span>)}</div> : null}
          </td>
          <td style={{ maxWidth: `${maxWidthOfColumns?.[14]}px` }} class="center">
            <Checkbox modelValue={item.isAuditorScanCount} onClick={() => {}}/>
          </td>

          <td style={{ maxWidth: `${maxWidthOfColumns?.[15]}px` }} class="link">
            {areaMeta.getUserCount(item.auditorController)}
            {item.auditorController.length ? <div class="table-hover">{item.auditorController.map(i => <span>{getUser(i.employee)}</span>)}</div> : null}
          </td>
          <td style={{ maxWidth: `${maxWidthOfColumns?.[16]}px` }} class="center">
            <Checkbox modelValue={item.isAuditorControllerCount} onClick={() => {}}/>
          </td>
          <td style={{ maxWidth: `${maxWidthOfColumns?.[17]}px` }} class="link">
            {areaMeta.getUserCount(item.storage)}
            {item.storage.length ? <div class="table-hover">{item.storage.map(i => <span>{getUser(i.employee)}</span>)}</div> : null}
          </td>
          <td style={{ maxWidth: `${maxWidthOfColumns?.[18]}px` }} class="center">
            <Checkbox modelValue={item.isStorageCount} onClick={() => {}}/>
          </td>
        </tr>
      )
    }

    let timeout: any

    watch(() => store.messagesWebSocket, async (v) => {
      clearTimeout(timeout)

      if (howManyAreas.value >= 99) {
        v.map(async mess => {
          if (mess.type.includes('_zone')) mutations.deleteMessageWS(mess)
          await nextTick()
        })

        await nextTick(() => { timeout = setTimeout(() => { props.table.value.reload() }, 1000) })
      } else {
        v.map(async mess => {
          if (mess.type.includes('_zone')) changeTableRow(props.table, mess)
          await nextTick()
        })
      }
    }, { deep: true })

    return () => (
      <div class="table-wrapper" id='myTable' onClick={contextMenu.close}>
        <CastomTable
          meta={areaMeta}
          headers={headers}
          line={line}
          ref={props.table}
          params={props.tableFilter.tableParams}
          style={`overflow: ${contextMenu.isShow.value ? 'hidden' : 'auto'}`}
          updateAllParams={props.updateAllParams}
          perPage={'1000'}
        />
        {contextMenu.isShow.value ? <ContextMenu
          stylePosition={contextMenu.coordinates4style.value}
          close={contextMenu.close}
          setTypeModal={props.setTypeModal}
          markIsAutoAssignment={openConfirmModal}
        /> : null}
        {isShowConfirmModal.value && <ModalForm
          cancel={closeConfirmModal}
          confirm={() => {}}
          header='Подтверждение'
          slots={{
            default: () => (<p class="modal-text mt-3">{text4ConfirmModal.value}</p>),
            buttons: () => (<div class="buttons mt-3">
              <button onClick={closeConfirmModal} class="default-button">Нет</button>
              <button onClick={markIsAutoAssignment4contextMenu} class="primary-button">Да</button>
            </div>)
          }}
        />}
      </div>
    )
  }
})
