
import axios from '@/axios'
import { EntityMeta, LineLoaderSmall, useScrollPagination } from 'apptimizm-ui'
import { AxiosError } from 'axios'
import { PropType, Ref, computed, defineComponent, onBeforeUnmount, onMounted, ref, watch } from 'vue'
import { IPaginationResponse, IEventWithErrors } from '@/core/types'
import { debounce } from 'lodash'
import ArrowIcon from '../svg-components/ArrowIcon.vue'
import Filters, { TAdd4filterParams } from './Filters.vue'

export interface IHeader {
  name: string
  sortAndFilterKey: string
  filterTypes?: TAdd4filterParams[]
}

export interface IExpose<T> {
    items: T[]
    count: number
    reload: () => Promise<void>
}

export interface IParams { [key:string]: string | boolean }

export default defineComponent({
  props: {
    meta: {
      type: Object as PropType<EntityMeta<unknown>>,
      required: true
    },
    headers: {
      type: Object as PropType<IHeader[]>,
      required: true
    },
    line: {
      // eslint-disable-next-line no-undef
      type: Function as PropType<(item: any, maxWidthOfColumns?: number[]) => JSX.Element>,
      required: true
    },
    params: {
      type: Object as PropType<IParams>,
      default: () => {}
    },
    updateAllParams: {
      type: Function as PropType<(params: IParams) => void>,
      default: () => {}
    },
    perPage: {
      type: String,
      default: '50'
    }
  },
  setup (props, app) {
    // Получение данных
    const pagination = ref(new IPaginationResponse())

    const page = ref(1)

    const params = computed(() => {
      const results: IParams = {
        ...props.params,
        ...filterParams.value,
        per_page: props.perPage
      }

      if (sortParam.value) {
        results.ordering = sortParam.value
      }
      props.updateAllParams(results)
      return results
    })

    const isLoading = ref(false)

    async function getItems () {
      if (isLoading.value) return
      isLoading.value = true

      try {
        const responseData = (await axios.get(props.meta.endpoint, { params: { ...params.value, page: page.value } })).data as IPaginationResponse<unknown>

        pagination.value.count = responseData.count
        pagination.value.next = responseData.next
        pagination.value.previous = responseData.previous
        pagination.value.results = [...pagination.value.results, ...responseData.results.map((item) => props.meta.load(item))]
      } catch (error) {
        const eventError400: IEventWithErrors = new Event('Error 400')
        eventError400.errors = (error as AxiosError<{ errors: string[] }>).response?.data?.errors
        window.dispatchEvent(eventError400)
      } finally {
        isLoading.value = false
      }
    }

    onMounted(async () => {
      await getItems()
    })
    //

    // Фильтрация
    const filterParams = ref<IParams>({})

    function setFilterParams (newVal: IParams) {
      filterParams.value = newVal
    }
    //

    // Сортировка
    const sortParam = ref('')

    const orderingDirections = ['', 'decreasing', 'increasing']

    const orderingDirectionIndex = ref(0)

    const orderingDirection = computed(() => orderingDirections[orderingDirectionIndex.value])

    function setSortParam (param: string) {
      if (orderingDirectionIndex.value + 1 < orderingDirections.length) {
        orderingDirectionIndex.value += 1
      } else {
        orderingDirectionIndex.value = 0
      }

      if (orderingDirection.value) {
        sortParam.value = orderingDirection.value === 'increasing' ? param : `-${param}`
      } else {
        sortParam.value = ''
      }
    }

    function getIsActiveArrow (headerParam: string, arrowType: 'increasing' | 'decreasing') {
      const headerSortParam = arrowType === 'decreasing' ? `-${headerParam}` : headerParam
      return headerSortParam === sortParam.value && orderingDirection.value === arrowType
    }
    //

    // Пагинация по скроллу
    const trigger = ref(null) as unknown as Ref<HTMLElement>

    function showMore () {
      if (!pagination.value.next) return
      page.value += 1
      getItems()
    }

    useScrollPagination(showMore, trigger)
    //

    // Обновление данных, при изменении параметров запроса
    const debounceGetItems = debounce(getItems, 1000)

    function reload () {
      pagination.value = new IPaginationResponse()
      page.value = 1
      debounceGetItems()
    }

    watch(() => params.value, () => {
      reload()
    })
    //

    // Прокидывание данных наружу
    const count = computed(() => pagination.value.count)

    const items = computed(() => pagination.value.results)

    app.expose({
      items,
      count,
      reload
    })
    //

    // Управление ресайзом
    const maxWidthOfColumns = ref<number[]>([])

    function resize (e: Event, indexCol: number) {
      const headerWidth = (e.target as HTMLElement)?.clientWidth
      maxWidthOfColumns.value[indexCol] = headerWidth
    }
    //

    return () => (
      <div class="castom-table">
        <tr class="headers">
          {props.headers.map((header, index) => (
            <th class="header-item">
              <div onTransitionend={(e) => { resize(e, index) }}>
                <span class="arrows" onClick={() => setSortParam(header.sortAndFilterKey)}>
                  <ArrowIcon class={`top ${getIsActiveArrow(header.sortAndFilterKey, 'increasing') ? 'active' : ''}`} />
                  <ArrowIcon class={`bottom ${getIsActiveArrow(header.sortAndFilterKey, 'decreasing') ? 'active' : ''}`} />
                </span>
                <span class="name">{header.name}</span>
              </div>
            </th>
          ))}
        </tr>
        <Filters
          headers={props.headers}
          setFilterParams={setFilterParams}
        />
        {items.value.map((item) => props.line(item, maxWidthOfColumns.value))}
        {isLoading.value && <LineLoaderSmall/>}
        <div class="trigger" ref={trigger}/>
      </div>
    )
  }
})
