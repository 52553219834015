
import { PropType, computed, defineComponent } from 'vue'
import { TTypeModal4Areas } from './index.vue'

export default defineComponent({
  props: {
    stylePosition: {
      type: Object as PropType<{ left: string, top: string }>,
      required: true
    },
    close: {
      type: Function as PropType<() => void>,
      required: true
    },
    setTypeModal: {
      type: Function as PropType<(type: TTypeModal4Areas) => void>,
      required: true
    },
    markIsAutoAssignment: {
      type: Function as PropType<(isAutoAssignment: boolean) => void>,
      required: true
    }
  },
  setup (props) {
    function clickItem (callback: () => void) {
      props.close()
      callback()
    }

    const actionList = [
      { text: 'Выдать задание', clickItem: () => { clickItem(() => { props.setTypeModal('issusing') }) } },
      { text: 'Назначить склад', clickItem: () => { clickItem(() => { props.setTypeModal('assign') }) } },
      { text: 'Удалить', clickItem: () => { clickItem(() => { props.setTypeModal('delete') }) } },
      { text: 'Исключить из автоназначения', clickItem: () => { clickItem(() => { props.markIsAutoAssignment(false) }) } },
      { text: 'Добавить в автоназначение', clickItem: () => { clickItem(() => { props.markIsAutoAssignment(true) }) } }
    ]

    return () => (<ul
      class="context-menu"
      style={props.stylePosition}
      onClick={(e: MouseEvent) => { e.stopPropagation() }}
    >
      {actionList.map((listItem) => (
        <li onClick={listItem.clickItem}>{listItem.text}</li>
      ))}
    </ul>)
  }
})
