import { defineComponent, PropType, ref, Ref } from 'vue'
import { DefaultInput, FormErrors, ModalForm, NumberInput } from 'apptimizm-ui'
import { Area, AreaMeta, FormFields, FormFieldsMeta } from './types'
import { store } from '@/store/index'
import axios from '@/axios'
import { howManyAreas } from './index.vue'
import { ButtonLoader } from '@/UI/loader'

export default defineComponent({
  props: {
    close: {
      type: Function as PropType<() => void>,
      required: true
    },
    selectedAreas: {
      type: Array as PropType<string[]>,
      required: true
    },
    reloadTable: {
      type: Function as PropType<() => void>,
      default: () => {}
    },
    numberMessaggeReload: {
      type: Number,
      default: 0
    }
  },
  setup (props) {
    const areaMeta = new AreaMeta()
    const form = ref(new FormFields())
    const formMeta = new FormFieldsMeta()
    const formErrors = ref(new FormErrors({}))
    const isLoading = ref(false)

    const submit = async () => {
      isLoading.value = true

      if (form.value.startSerialNumber && form.value.endSerialNumber) {
        howManyAreas.value = Math.abs(form.value.endSerialNumber - form.value.startSerialNumber)

        const params = {
          project: store.currentProject.id,
          serial_number_start: form.value.startSerialNumber,
          serial_number_end: form.value.endSerialNumber,
          per_page: 500
        }

        const areas = ((await axios.get(areaMeta.endpoint, { params })).data.results).map((x: Area) => ({ id: x.id, storage_name: form.value.storageName }))
        await axios.patch(formMeta.endpointUpdate, areas)
      } else {
        howManyAreas.value = props.selectedAreas.length

        const requests = props.selectedAreas.map(async (id) => (
          axios.patch(`${areaMeta.endpoint}/${id}`, formMeta.dump(form.value))
        ))
        await Promise.all(requests)
      }

      form.value.reset()
      props.close()
      isLoading.value = false
    }

    const modalForm = () => (
      <ModalForm
        cancel={props.close}
        confirm={() => {}}
        header='Назначить склад'
        slots={{
          default: () => (
            <>
              { !props.selectedAreas.length
                ? <div class="row cg-10 mt-4">
                  <label>
                    <NumberInput
                      class={`short input-placeholder-up ${!form.value.startSerialNumber ? 'hide-zero' : ''}`}
                      placeholder="Зоны с..."
                      modelValue={form.value.startSerialNumber}
                      onValueChange={(v: number) => { form.value.startSerialNumber = parseInt(String(v)) } }
                      errors={formErrors.value.forField('startSerialNumber')}
                    />
                  </label>
                  <label>
                    <NumberInput
                      class={`short input-placeholder-up ${!form.value.endSerialNumber ? 'hide-zero' : ''}`}
                      placeholder="по..."
                      modelValue={form.value.endSerialNumber}
                      onValueChange={(v: number) => { form.value.endSerialNumber = parseInt(String(v)) } }
                      errors={formErrors.value.forField('amount')}
                    />
                  </label>
                </div>
                : null
              }
              <div class='mt-2'>
                <label>
                  <DefaultInput
                    class={form.value.storageName ? 'input-placeholder-up' : ''}
                    placeholder="Код склада"
                    modelValue={form.value.storageName}
                    onValueChange={(v: string) => { form.value.storageName = v } }
                    errors={formErrors.value.forField('storageName')}
                  />
                </label>
              </div>
            </>
          ),
          buttons: () =>
            <button
              onClick={submit}
              disabled={isLoading.value}
              class="primary-button mt-3">
              <ButtonLoader hidden={!isLoading.value}/>
              Назначить
            </button>
        }}
      />
    )
    return () => (
      <div>{modalForm()}</div>
    )
  }
})