
import { IExpose, IParams } from '@/UI/CastomTable/index.vue'
import Checkbox from '@/UI/checkbox'
import { computed, defineComponent, onBeforeUnmount, watch } from '@vue/runtime-core'
import { NumberInput } from 'apptimizm-ui'
import axios from '@/axios'
import { mutations, store } from '@/store/index'
import { useSelectedList } from '@/composables/use-selected-list'
import { host } from '@/const'
import { getAllList } from '@/utils'
import { Ref, onMounted, ref } from 'vue'
import { ISelectValue, ProjectMeta, RmmSettingsMeta } from '../projects/types'
import AssignWarehouse from './assignWarehouse'
import { Employee } from '../employee/types'
import Table from './table.vue'
import IssusingTask from './issuingTask'
import CreateArea from './createArea'
import DeleteArea from './deleteAreas'
import ReportInZones from './reportInZones.vue'
import Statistics from './statistics.vue'
import { Area, AreaMeta, FormFields } from './types'

export type TTypeModal4Areas = 'create' | 'delete' | 'assign' | 'issusing' | 'report' | ''

export interface IAreaFilter4table {
  serialNumberStart: number
  serialNumberEnd: number
  tableParams: {
    [key: string]: string
  }
}
export const howManyAreas: Ref<number> = ref(0)

export default defineComponent({
  setup () {
    const table = ref(null) as unknown as Ref<IExpose<Area>>
    const projecttMeta = new ProjectMeta()
    const rmmSettingsMeta = new RmmSettingsMeta()
    const typeModal = ref<TTypeModal4Areas>('')
    const setTypeModal = (type: TTypeModal4Areas) => {
      typeModal.value = type
      employeeList.value = []
      form.value.reset()
    }
    const closeModal = () => {
      setTypeModal('')
      // selectedList.items.value = []
    }
    const roleChoices: Ref<ISelectValue[]> = ref([])
    const employeeList: Ref<Employee[]> = ref([])
    const form = ref(new FormFields())

    const isProjectSelected = computed(() => !!store.currentProject.id)
    const isCanAutoassignment = ref<boolean>(false)

    const selectedList = useSelectedList<Area>()
    const areaMeta = new AreaMeta()

    const numberMessageToReloadTable = ref(35)

    async function selectAreaAll (e: KeyboardEvent) {
      if (e.ctrlKey && e.code === 'KeyA') {
        selectedList.items.value = await getAllList(areaMeta, tableFilter.value.tableParams)
      }
    }

    watch(() => table.value?.items, () => {
      selectedList.items.value = []
    })

    const changeProject = async (v: any) => {
      const { rmmSettings: rmmId, autoAssignEnbale: enable } = projecttMeta.load((await axios.get(projecttMeta.endpoint + v.id)).data)
      const { autoZonesAmount } = rmmSettingsMeta.load((await axios.get(rmmSettingsMeta.endpoint + rmmId)).data)

      isAutoAssignEnable.value = enable

      isCanAutoassignment.value = !!autoZonesAmount
      selectedList.items.value = []
      mutations.setCurrentProject(v)
    }

    onMounted(async () => {
      document.addEventListener('keydown', selectAreaAll)

      if (!isProjectSelected.value && localStorage.projectID) {
        try {
          const result = projecttMeta.load((await axios.get(`${projecttMeta.endpoint}${localStorage.projectID}`)).data)
          if (result?.id) mutations.setCurrentProject({ id: result.id, name: result.name, address: result.address })
        } catch (error) {
          mutations.pushNotification('Не удалось получить выбранный проект', true)
        }
      }

      roleChoices.value = (await axios.get(`${host}/api/v1/task/types_choices/`)).data

      if (!isProjectSelected.value) return
      const { rmmSettings: rmmId, autoAssignEnbale: enable } = projecttMeta.load(
        (await axios.get(projecttMeta.endpoint + store.currentProject.id)).data
      )

      isAutoAssignEnable.value = enable

      const { autoZonesAmount } = rmmSettingsMeta.load(
        (await axios.get(rmmSettingsMeta.endpoint + rmmId)).data
      )

      isCanAutoassignment.value = !!autoZonesAmount
    })

    onBeforeUnmount(() => {
      document.removeEventListener('keydown', selectAreaAll)
    })

    const serialNumberStart = ref(0)

    const serialNumberEnd = ref(0)

    const tableFilter = computed(() => {
      const tableFilter: IAreaFilter4table = {
        serialNumberStart: serialNumberStart.value,
        serialNumberEnd: serialNumberEnd.value,
        tableParams: {
          ordering: 'serial_number',
          project: store.currentProject.id
        }
      }

      if (serialNumberStart.value) tableFilter.tableParams.serial_number_start = String(serialNumberStart.value)
      if (serialNumberEnd.value) tableFilter.tableParams.serial_number_end = String(serialNumberEnd.value)

      return tableFilter
    })

    // Управление блоком со статистикой
    const isShowStatistics = ref(false)

    function toggleStatistics () {
      isShowStatistics.value = !isShowStatistics.value
    }

    const allParams = ref<IParams>({})

    function setAllParams (params: IParams) {
      allParams.value = params
    }
    //

    // Автоназначение
    const isAutoassignmentSuccess = ref<boolean>(false)
    const isAutoAssignEnable = ref<boolean>(false)

    const autoassignmentSuccess = async (val: boolean) => {
      isAutoassignmentSuccess.value = val
      isAutoAssignEnable.value = val
      await axios.patch(projecttMeta.endpoint + store.currentProject.id, { auto_assign_enbale: isAutoAssignEnable.value })
    }

    async function autoassignment () {
      const response = (await axios.post(`${areaMeta.endpoint}/auto_assignment_tasks/`, {
        project: store.currentProject.id
      })).data
      if (response?.errors) {
        if (response.errors.error) mutations.pushNotification(response.errors.error, true)
      } else {
        autoassignmentSuccess(true)
      }
    }

    const checkAutoAssign = async () => {
      isAutoAssignEnable.value = false
      isAutoassignmentSuccess.value = false
      await axios.patch(projecttMeta.endpoint + store.currentProject.id, { auto_assign_enbale: isAutoAssignEnable.value })
    }
    //
    // перезагрузка таблицы, если зон больше 29
    function reloadTable () {
      console.log('reload', table.value.items)
      // table.value.reload()
    }

    function resetSelectedAreas () {
      selectedList.items.value = []
    }

    return () => (
      <div class="page">
        <div class="page-top">
          <div class="page-top-panel">
            <h1>Зоны</h1>
            <div class="page-top-panel-row mt-5">
              <label>
                <NumberInput
                  class={`short input-placeholder-up ${!tableFilter.value.serialNumberStart ? 'hide-zero' : ''}`}
                  placeholder="Зоны с..."
                  modelValue={tableFilter.value.serialNumberStart}
                  onValueChange={(v: number) => { serialNumberStart.value = parseInt(String(v)) } }
                />
              </label>
              <label>
                <NumberInput
                  class={`short input-placeholder-up ${!tableFilter.value.serialNumberEnd ? 'hide-zero' : ''}`}
                  placeholder="по..."
                  modelValue={tableFilter.value.serialNumberEnd}
                  onValueChange={(v: number) => { serialNumberEnd.value = parseInt(String(v)) }}
                />
              </label>
              <button disabled={!isProjectSelected.value} onClick={() => setTypeModal('create')} class="button-add right-item">Создать зоны</button>
              <button disabled={!isProjectSelected.value} onClick={() => setTypeModal('delete')} class="button-delete">Удалить зоны</button>
              <button disabled={!isProjectSelected.value} onClick={() => setTypeModal('assign')} class="button-storage">Назначить склад</button>
              <button disabled={!isProjectSelected.value} onClick={() => setTypeModal('issusing')} class="default-button">Выдать задание</button>

              <div class="autoAssign">
                <button
                  style={{
                    background: isAutoAssignEnable.value ? '#005CBF' : '#E4E5E8',
                    color: isAutoAssignEnable.value ? '#fff' : '#000',
                    borderRadius: isAutoAssignEnable.value ? '4px 0 0 4px' : '4px'
                  }}
                  onClick={autoassignment}
                  disabled={!isCanAutoassignment.value}
                  class="default-button">Автоназначение
                </button>

                { isAutoAssignEnable.value || isAutoassignmentSuccess.value
                  ? <Checkbox
                    onClick={() => checkAutoAssign()}
                    placeholder={''}
                    modelValue={isAutoAssignEnable.value}
                  />
                  : null
                }
              </div>
            </div>
          </div>
          <div class="layout">
            {isProjectSelected.value ? <Table
              class={{ 'show-statistics': isShowStatistics.value }}
              table={table}
              tableFilter={tableFilter.value}
              isAutoAssignEnable={isAutoAssignEnable}
              selectedList={selectedList as any}
              setTypeModal={setTypeModal}
              updateAllParams={setAllParams}
            /> : null}
          </div>
        </div>
        {!!isProjectSelected.value && isShowStatistics.value && <Statistics filters={allParams.value}/>}
        {!!isProjectSelected.value && <div class="bottom-row">
          { table.value && (<p style="margin: auto; margin-left: 0;">Всего: {table.value?.count}</p>)}
          <button
            class="button-statistics mr-3"
            onClick={toggleStatistics}
          >Статистика</button>
          <button
            class="button-save right-item"
            onClick={() => setTypeModal('report')}
          >Отчет по инвентаризации в зонах</button>
        </div>}
        {typeModal.value === 'issusing' &&
          <IssusingTask
            selectedAreas={selectedList.itemsId.value}
            close={closeModal}
            roles={roleChoices.value}
            reloadTable={reloadTable}
            numberMessaggeReload={numberMessageToReloadTable.value}
          />
        }
        {typeModal.value === 'create' &&
          <CreateArea
            close={closeModal}
            reloadTable={reloadTable}
            numberMessaggeReload={numberMessageToReloadTable.value}
            table={table}
          />
        }
        {typeModal.value === 'delete' &&
          <DeleteArea
            resetSelectedAreas={resetSelectedAreas}
            close={closeModal}
            selectedAreas={selectedList}
            reloadTable={reloadTable}
            numberMessaggeReload={numberMessageToReloadTable.value}
          />
        }
        {typeModal.value === 'assign' &&
          <AssignWarehouse
            close={closeModal}
            selectedAreas={selectedList.itemsId.value}
            reloadTable={reloadTable}
            numberMessaggeReload={numberMessageToReloadTable.value}
          />
        }
        {typeModal.value === 'report' && <ReportInZones close={closeModal} zoneIds={selectedList.itemsId.value}/>}
      </div>
    )
  }
})
