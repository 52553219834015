<script lang="ts">
import { PropType, computed, defineComponent, onMounted, ref, watch } from 'vue'
import Datepicker from 'vuejs-datetimepicker'

export default defineComponent({
  // eslint-disable-next-line vue/no-unused-components
  components: { Datepicker },
  props: {
    setValue: {
      type: Function as PropType<(newVal: string) => void>,
      required: true
    }
  },
  setup (props) {
    function setFormatValue (newVal: string) {
      props.setValue(newVal.replace(' ', 'T'))
    }

    const datepicker = ref<any>(null)

    onMounted(() => {
      datepicker.value.days = ['пн', 'вт', 'сп', 'чт', 'пт', 'сб', 'вс']
      datepicker.value.months = [
        'январь',
        'февраль',
        'март',
        'апрель',
        'май',
        'июнь',
        'июль',
        'август',
        'сентябрь',
        'октябрь',
        'ноябрь',
        'декабрь'
      ]

      const dateInputs = (datepicker.value.$root.$el as HTMLElement).querySelectorAll('#tj-datetime-input')
      for (const dateInput of dateInputs) {
        (dateInput as HTMLInputElement).readOnly = true
      }
    })

    function openContextMenu (event: Event) {
      event.preventDefault()
      datepicker.value.toggleCal()
    }

    const isShowClose = computed(() => {
      return datepicker.value?.date
    })

    return { setFormatValue, datepicker, openContextMenu, isShowClose }
  }
})
</script>

<template>
<div class="datitime-picker" @contextmenu="openContextMenu">
  <Datepicker
    ref="datepicker"
    format="YYYY-MM-DD H:i"
    @input="setFormatValue"
    firstDayOfWeek="1"
  />
  <img
    v-if="isShowClose"
    @click="datepicker.clearDate"
    class="close"
    src="@/assets/image/close-icon.svg"
    alt="close"
  />
</div>
</template>

<style scoped lang="sass">
@import '@/styles/variables'
.datitime-picker
  position: relative
  display: flex
  gap: 8px
  align-items: center
  cursor: pointer
  div
    width: 100%
    position: relative
    &:after
      content: ''
      position: absolute
      top: 0
      right: -80px
      bottom: 0
      width: 100px
      background-color: white
  /deep/
    input
      cursor: pointer
      border: none
      height: 14px
    .year-month-wrapper, button, .activePort, .active
      background: $primary-color
    button
      &:hover
        background: $primary-color
    .port:not(.activePort)
      &:hover
        color: $primary-color
    .days, .okButton
      color: $primary-color
    .okButton
      background: none
      &:hover
        background: none
.close
  background-color: $primary-color
  position: absolute
  right: 12px
</style>
