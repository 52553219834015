export class IPaginationResponse<T> {
  count: number = 0
  next: string | null = null
  previous: string | null = null
  results: T[] = []
}

export interface IEventWithErrors extends Event {
  errors?: string[]
}
