import { computed, reactive, ref } from 'vue'

export function useContextMenu () {
  const isShow = ref(false)

  const coordinates = reactive({
    x: 0,
    y: 0
  })

  const coordinates4style = computed(() => (
    { left: `${coordinates.x}px`, top: `${coordinates.y}px` }
  ))

  const toggle = async (event: any, blockHeight?: number) => {
    event.preventDefault()

    const x = event.pageX
    let y = event.pageY

    if (blockHeight && y > blockHeight) y = blockHeight - 20

    coordinates.x = x
    coordinates.y = y

    isShow.value = !isShow.value
  }

  function close () {
    isShow.value = false
  }

  return {
    isShow,
    coordinates,
    coordinates4style,
    toggle,
    close
  }
}